<template>
  <div
    class="card mb-4"
    v-if="
      personImage.length > 0 &&
      this.$store.state.getMovie.status.personImageShowLoading
    "
  >
    <div class="card-header bg-success">
      <h4 class="text-center text-white my-2">تصاویر {{ person.name }}</h4>
    </div>
    <div class="card-body">
      <div class="row">
        <div
          class="col-md-3 col-lg-2 my-1"
          v-for="photo in personImage"
          :key="photo.file_path"
        >
          <img
            style="width: 300px"
            class="img-fluid rounded-1"
            v-if="photo.file_path"
            :src="imageSizeMovie('w185') + photo.file_path"
            :title="person.name"
            :alt="person.name"
          />
          <img
            style="width: 300px"
            class="img-fluid rounded-1"
            v-else
            src="../../assets/no_image.jpg"
            :title="person.name"
            :alt="person.name"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImagePerson",
  props: {
    person: {
      type: Object,
      required: true,
    },
    personImage: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped></style>
